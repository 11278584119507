import {
  Configure,
  InfiniteHits,
  InstantSearch,
  useStats
} from 'react-instantsearch';

import {
  NoResultsBoundary,
  PageNotFound,
  SectionTitle,
  TerminationPendingExpiredUserEventCard
} from '@/components/atomic/atoms';
import {
  DEFAULT_HITS_PER_PAGE_FOR_TERMINATION_PENDING_EXPIRED_USER_EVENT,
  SECTION_NAME
} from '@/config/searchkit/actionCenter';
import { NAVIGATION_INDEX } from '@/config/searchkit/navigation';
import { openTerminationPendingExpiredUserEvents } from '@/services/actionCenter.service';

const TerminationPendingExpiredUserEventBody = ({ selectedPlannerId }) => {
  const { nbHits: count } = useStats();
  return (
    <div className='flex flex-col gap-4 shadow-navigate-cart rounded-lg bg-white p-4 my-4'>
      <div className='flex justify-between items-center'>
        <SectionTitle
          {...{
            count,
            title: 'Expired Events Need Some Attention',
            viewAllCTAProps: {
              className:
                count > 0
                  ? 'cursor-pointer shadow-card py-1 px-2 rounded'
                  : 'hidden',
              onClick: () =>
                openTerminationPendingExpiredUserEvents({
                  selectedPlannerId
                })
            }
          }}
        />
      </div>
      <NoResultsBoundary
        fallback={
          <PageNotFound
            {...{
              className: 'pt-4',
              iconHeight: 114,
              iconWidth: 120,
              notFoundImage: 'dashboard-event-not-found.svg',
              title: `Awesome! You're all caught up`,
              titleStyle: 'font-light text-dim-gray mt-2'
            }}
          />
        }
      >
        <InfiniteHits
          {...{
            classNames: {
              item: 'p-0 border-none shadow-none w-full !block',
              list: 'flex gap-4 w-full',
              loadMore: 'hidden',
              root: 'flex flex-1 max-h-[29rem] overflow-auto no-scrollbar'
            },
            hitComponent: ({ hit }) => (
              <TerminationPendingExpiredUserEventCard
                {...{ hit, selectedPlannerId }}
              />
            ),
            showPrevious: false
          }}
        />
      </NoResultsBoundary>
    </div>
  );
};

const TerminationPendingExpiredUserEvent = ({
  searchClient,
  selectedPlannerId
}) => (
  <InstantSearch
    {...{
      future: {
        preserveSharedStateOnUnmount: true
      },
      indexName: NAVIGATION_INDEX,
      searchClient
    }}
  >
    <Configure
      {...{
        hitsPerPage:
          DEFAULT_HITS_PER_PAGE_FOR_TERMINATION_PENDING_EXPIRED_USER_EVENT,
        plannerId: selectedPlannerId,
        sectionName: SECTION_NAME.EXPIRED_EVENTS
      }}
    />
    <TerminationPendingExpiredUserEventBody {...{ selectedPlannerId }} />
  </InstantSearch>
);

export default TerminationPendingExpiredUserEvent;
