import * as HttpService from 'services/http.service';
import { POST_METABASE_DASHBOARD_SIGNED_API_URL } from 'services/url.service';

export const generateMetabaseDashboardSignedAPI = (data) =>
  HttpService.postWithAuth(POST_METABASE_DASHBOARD_SIGNED_API_URL(), data);

export const eventTypeDashboard = {
  dashboard: {
    description:
      'Use this planner board to plan your perfect event. Plan + Product + Partner + Price = a winning quote',
    params: { event_id: null, user_id: null },
    title: 'Planner EventType DashBoard',
    queryParameters: {
      bordered: false,
      questionNo: 333,
      theme: 'transparent',
      titled: false
    }
  }
};

export const productDiscoveryDashBoard = {
  dashboard: {
    description: 'Planner PDP Helper Dashboard',
    params: { product_id: null },
    title: 'PDP Dashboard',
    queryParameters: {
      bordered: false,
      questionNo: 335,
      theme: 'transparent',
      titled: false
    }
  },
  hideBreadCrumb: true
};
