import Image from 'next/image';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { SearchBox } from 'react-instantsearch';
import { createInstantSearchRouterNext } from 'react-instantsearch-router-nextjs';

import { ScopeSearchAttributeModal } from '@/components/atomic/atoms';
import { Button, Text, TextWithIcon } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import {
  MIN_CHAR_FOR_SEARCH_REQUEST,
  PLANNER_PRODUCT_INDEX,
  SEARCHABLE_ATTRIBUTES
} from '@/config/searchkit/productSearch';
import useEventListener from '@/hooks/useEventListener';
import { PLANNER_PORTAL_URL } from '@/services/connections.service';
import { PAGE_NAME } from '@/services/plannerPortal.service';

const productSearchResultPath = PAGE_NAME.PRODUCT_SEARCH_RESULT.template;

const ProductSearchBar = ({
  animationContainerStyle = 'top-4',
  className = 'flex mx-auto relative -top-5',
  searchableAttribute,
  searchBoxWidth = 'w-45.6',
  searchButtonStyle = '',
  setSearchableAttribute
}) => {
  const router = useRouter();
  const [query, setQuery] = useState(null);
  const [isSearchBoxActive, setIsSearchBoxActive] = useState(false);

  const disableSearchRequest = query?.length < MIN_CHAR_FOR_SEARCH_REQUEST;

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setQuery(value);
  };

  const instantSearchRouter = createInstantSearchRouterNext();

  const onSearchQuery = () => {
    const urlParams = new URLSearchParams(
      instantSearchRouter.getLocation().search
    );
    if (searchableAttribute) {
      urlParams.append('searchableAttribute', searchableAttribute);
    }
    const url = new URL(productSearchResultPath, PLANNER_PORTAL_URL);
    url.search = urlParams.toString();
    const finalUrl = url.toString();
    router.push(finalUrl);
  };

  const handleKeyPress = (event) => {
    const isProductListPage = router.pathname === productSearchResultPath;
    const shouldDisableSearchButton = isProductListPage
      ? disableSearchRequest && query !== ''
      : disableSearchRequest;

    if (event.key === 'Enter') {
      event.preventDefault();
      if (!shouldDisableSearchButton) {
        setTimeout(() => {
          onSearchQuery();
        }, 1000);
      }
    }
  };

  const getQueryFromCurrentURL = () => {
    const urlParams = new URLSearchParams(
      instantSearchRouter.getLocation().search
    );
    const queryFromRoute = urlParams.get(`${PLANNER_PRODUCT_INDEX}[query]`);
    setQuery(queryFromRoute);
  };

  useEventListener('popState', getQueryFromCurrentURL);

  return (
    <div
      className={`rounded-full hover:border-brand bg-white quick-find-box shadow-navigate-cart p-1 h-[58px] ${className}`}
    >
      <ScopeSearchAttributeModal
        {...{
          searchableAttribute,
          searchAttributeLabelMap: SEARCHABLE_ATTRIBUTES,
          setSearchableAttribute,
          selectedAttributeLabel:
            SEARCHABLE_ATTRIBUTES.find(
              ({ esDocKey }) => esDocKey === searchableAttribute
            )?.label || SEARCHABLE_ATTRIBUTES[0].label
        }}
      />
      <div
        className='flex'
        onMouseEnter={() => setIsSearchBoxActive(true)}
        onMouseLeave={() => setIsSearchBoxActive(false)}
        onChange={handleSearchChange}
      >
        <SearchBox
          {...{
            classNames: {
              form: 'relative rounded-full',
              input: `${searchBoxWidth} flex relative h-14 -mt-1 -mr-5 text-base focus:outline-none focus:!border-none searchkit-searchbox-header border-none  pr-48 pl-10 rounded-r-full`
            },
            loadingIconComponent: () => <div className='hidden'></div>,
            onKeyPressCapture: handleKeyPress,
            submitIconComponent: () => (
              <div className='absolute left-3/4 top-2'></div>
            ),
            resetIconComponent: ({ classNames }) => (
              <div
                onClick={() => {
                  setQuery();
                  setIsSearchBoxActive(false);
                }}
                className={classNames.resetIcon}
              >
                <Image
                  {...{
                    alt: 'clear search',
                    height: 0,
                    src: `${staticMediaStoreBaseURL}/icons/clear-searchbox-icon.svg`,
                    style: { height: 12, width: 12 },
                    width: 0
                  }}
                />
              </div>
            )
          }}
        />

        <div className={`relative ${searchButtonStyle}`}>
          <Button
            {...{
              className:
                'bg-brand-gradient rounded-full text-white text-base font-medium self-center py-3 w-28 searchkit-home-submit-button mt-0',
              disabled: disableSearchRequest,
              label: 'Search',
              onClick: onSearchQuery,
              width: 'w-24'
            }}
          />
        </div>
      </div>
      {!query && !isSearchBoxActive && (
        <div
          className={`flex justify-center items-center absolute ${animationContainerStyle} left-[17rem]`}
        >
          <div className='flex gap-1 text-dim-gray font-medium'>
            <TextWithIcon
              {...{
                alt: 'search',
                className: '',
                icon: 'search_new.svg',
                iconStyle: 'relative top-0 mr-1',
                label: 'search in',
                labelStyle: 'flex text-base'
              }}
            />
            <div className='animation-container'>
              {SEARCHABLE_ATTRIBUTES.map(({ label }, index) => (
                <Text
                  key={index}
                  {...{
                    className: 'text-animation text-base',
                    content: label,
                    id: `animation-text-${index + 1}`
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductSearchBar;
