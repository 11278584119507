import Image from 'next/image';
import Link from 'next/link';

import { EPICExplanation } from '@/components/atomic/atoms';
import {
  Button,
  Text,
  TextWithIcon,
  Tooltip
} from '@/components/atomic/nuclei';
import { mediaStoreBaseURL, staticMediaStoreBaseURL } from '@/config/common';
import { getFormattedDate } from '@/lib/time';
import { parseNumberedLabel } from '@/lib/utils';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';

const NewAssignedUserEventCard = ({
  containerClassName = 'flex gap-4 bg-event-gradient rounded-t-[7px] w-[420px] p-3 relative',
  upcomingUserEventDetail: {
    checkoutEventIconUrl,
    eventCityOrAddress,
    eventDate,
    eventStageUpdatedAt,
    eventType,
    eventVerticalNames,
    expectedGuestCount,
    hostCreditEntityName,
    hostName,
    hostSegmentName,
    userEventId,
    userEventNumber,
    zendeskTicketId
  }
}) => {
  const tooltipConfigList = [
    { target: `zendesk-ticket-id-${userEventId}`, text: 'Zendesk Ticket Id' },
    { target: `user-event-number-${userEventId}`, text: 'User Event Number' },
    {
      target: `event-stage-updated-at-${userEventId}`,
      text: getFormattedDate({
        date: eventStageUpdatedAt,
        format: 'Do MMM YYYY, h.mm A'
      })
    }
  ];

  return (
    <Link
      className='shadow-navigate-cart h-36'
      href={getPageURL({
        pageName: PAGE_NAME.EVENT_CART_LIST.label,
        pathParams: { userEventId }
      })}
      target='_blank'
    >
      <div className='flex flex-col border border-pink rounded-lg shadow-navigate-cart hover:border-brand'>
        <div className={containerClassName}>
          <Image
            alt='event image'
            className='rounded-lg'
            height={0}
            src={`${mediaStoreBaseURL}/${checkoutEventIconUrl}`}
            style={{ height: 72, width: 72 }}
            unoptimized={true}
            width={0}
          />

          <Image
            alt='timing icon'
            className='absolute right-2 top-1'
            height={0}
            id={`event-stage-updated-at-${userEventId}`}
            src={`${staticMediaStoreBaseURL}/icons/timing-white-icon.svg`}
            style={{ height: 12, width: 12 }}
            width={0}
          />
          <Text
            {...{
              className: 'text-sm text-white truncate-text-three-line',
              content: (
                <EPICExplanation
                  {...{
                    epicParameters: {
                      city: eventCityOrAddress,
                      eventDate,
                      eventType,
                      guests: expectedGuestCount,
                      hostCreditEntityName,
                      hostName,
                      hostType: hostSegmentName,
                      userEventId,
                      verticals: eventVerticalNames
                    }
                  }}
                />
              )
            }}
          />
        </div>

        <div className='flex items-center p-3 justify-between'>
          <div className='flex gap-4'>
            <TextWithIcon
              {...{
                alt: 'zendesk icon',
                className: 'shadow-none',
                icon: 'zendesk-icon.svg',
                iconHeight: 20,
                iconWidth: 20,
                id: `zendesk-ticket-id-${userEventId}`,
                label: parseNumberedLabel(zendeskTicketId),
                labelStyle: 'text-sm font-medium text-dim-gray'
              }}
            />
            <TextWithIcon
              {...{
                alt: 'user event brand icon',
                className: 'shadow-none',
                icon: 'user-event-brand-icon.svg',
                iconHeight: 20,
                iconWidth: 20,
                id: `user-event-number-${userEventId}`,
                label: parseNumberedLabel(userEventNumber),
                labelStyle: 'text-sm font-medium text-dim-gray'
              }}
            />
          </div>
          <div className='gap-4 hidden'>
            <Button
              {...{
                className:
                  'border border-brand px-2 py-1 rounded-lg text-brand font-medium text-xs',
                label: 'Reassign'
              }}
            />
            <Button
              {...{
                className:
                  'px-2 py-1 rounded-lg text-white bg-brand-gradient font-medium text-xs',
                label: 'Accept'
              }}
            />
          </div>
        </div>
        <Tooltip {...{ configList: tooltipConfigList }} />
      </div>
    </Link>
  );
};

export default NewAssignedUserEventCard;
