import _ from 'lodash';

const TAB_CATEGORY_ENUM = {
  CART_COMPLETED: 'CART_COMPLETED',
  EPIC_ENHANCED: 'EPIC_ENHANCED',
  EVENT_ACCEPTED: 'EVENT_ACCEPTED',
  EVENT_CREATED: 'EVENT_CREATED',
  NOT_CONVERTED: 'NOT_CONVERTED',
  ORDER_CONFIRMED: 'ORDER_CONFIRMED',
  ORDER_PLACED: 'ORDER_PLACED',
  SOLUTION_DESIGN: 'SOLUTION_DESIGN'
};

export const DEFAULT_TAB_CATEGORY = TAB_CATEGORY_ENUM.EVENT_ACCEPTED;

export const DEFAULT_HITS_PER_PAGE_FOR_TERMINATION_PENDING_EXPIRED_USER_EVENT = 10;

export const DEFAULT_HITS_PER_PAGE = 5;

export const TAB_CATEGORY_CONFIG_LIST = [
  {
    activeIcon: 'epic-enhance-white-icon',
    className: 'in-progress-badge',
    icon: 'epic-enhance-brand-icon',
    key: TAB_CATEGORY_ENUM.EPIC_ENHANCED,
    label: 'EPIC Enhanced',
    sortOrder: 2
  },
  {
    activeIcon: 'user-event-white-icon',
    className: 'text-bluish-cyan',
    icon: 'user-event-brand-icon',
    key: TAB_CATEGORY_ENUM.EVENT_CREATED,
    label: 'Events w/o EPIC',
    sortOrder: 0
  },
  {
    activeIcon: 'solution-white-icon',
    className: 'submitted-badge',
    icon: 'solution-brand-icon',
    key: TAB_CATEGORY_ENUM.SOLUTION_DESIGN,
    label: 'Solution Design',
    sortOrder: 3
  },
  {
    activeIcon: 'event-accepted-white-icon',
    className: 'past-badge',
    icon: 'event-accepted-brand-icon',
    key: TAB_CATEGORY_ENUM.EVENT_ACCEPTED,
    label: 'Event Accepted',
    sortOrder: 1
  },
  {
    activeIcon: 'cart-complete-white-icon',
    className: 'submitted-badge',
    icon: 'cart-complete-brand-icon',
    key: TAB_CATEGORY_ENUM.CART_COMPLETED,
    label: 'Cart Completed',
    sortOrder: 4
  },
  {
    activeIcon: 'order-placed-white-icon',
    className: 'submitted-badge',
    icon: 'order-placed-brand-icon',
    key: TAB_CATEGORY_ENUM.ORDER_PLACED,
    label: 'Order Placed',
    sortOrder: 5
  },
  {
    activeIcon: 'order-confirmed-white-icon',
    className: 'submitted-badge',
    icon: 'order-confirmed-brand-icon',
    key: TAB_CATEGORY_ENUM.ORDER_CONFIRMED,
    label: 'Order Confirmed',
    sortOrder: 6
  },
  {
    activeIcon: 'event-not-converted-white-icon',
    className: 'submitted-badge',
    icon: 'event-not-converted-brand-icon',
    key: TAB_CATEGORY_ENUM.NOT_CONVERTED,
    label: 'Not Converted',
    sortOrder: 7
  }
]
  .sort((a, b) => a.sortOrder - b.sortOrder)
  .map((a) => _.omit(a, 'sortOrder'));

export const SECTION_NAME = {
  EXPIRED_EVENTS: 'EXPIRED_EVENTS',
  GLANCE_EVENT_PIPELINE: 'GLANCE_EVENT_PIPELINE'
};
