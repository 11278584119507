import {
  PaymentPendingExpiredUserEventCard,
  SectionTitle
} from '@/components/atomic/atoms';
import { isDevelopmentOrRNDEnv } from '@/config/common';

const PaymentPendingExpiredUserEvent = () => (
  <div
    className={`${
      !isDevelopmentOrRNDEnv ? 'hidden' : ''
    } flex flex-col gap-4 shadow-navigate-cart rounded-lg bg-white p-4 my-4`}
  >
    <div className='flex justify-between items-center'>
      <SectionTitle
        {...{ title: 'Expired Booked Events With Outstanding Payment' }}
      />
    </div>
    <div className='flex gap-4 overflow-auto no-scrollbar'>
      <PaymentPendingExpiredUserEventCard />
    </div>
  </div>
);

export default PaymentPendingExpiredUserEvent;
