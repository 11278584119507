import { memo, useMemo } from 'react';
import {
  CurrentRefinements,
  InfiniteHits,
  useCurrentRefinements,
  useSearchBox,
  useStats
} from 'react-instantsearch';

import {
  ListCard,
  NoResultsBoundary,
  PageNotFound
} from '@/components/atomic/atoms';
import { Text } from '@/components/atomic/nuclei';
import transformCurrentRefinementItemsLabel from '@/helpers/searchkit';
import { transformSearchResultList } from '@/services/navigation.service';

const currentRefinementClassGroup = {
  category: 'pl-1 pr-2 py-2 rounded-md flex !ml-0 text-xs font-light',
  delete: '!ml-2 p-0 self-center searchkit-filter-delete-icon',
  item: 'bg-white border-brand px-2 rounded-md flex flex-wrap gap-2 searchkit-selected-filters',
  label: 'font-medium text-nero text-sm',
  list: '!flex flex-wrap !gap-2 !mb-0'
};

const handleTransformItems = (hits) => {
  const transformedHits = transformSearchResultList({ searchResults: hits });
  return transformedHits;
};

const OrderListSearchResultsPanel = memo(({ showFilters }) => {
  const memoizedHandleTransformItems = useMemo(() => handleTransformItems, []);
  const { items: currentRefinements } = useCurrentRefinements();
  const { nbHits: resultsCount } = useStats();
  const { query } = useSearchBox();

  return (
    <div
      className={`flex flex-col px-2 w-full h-screen relative ${
        showFilters ? 'ml-80' : 'ml-0'
      }`}
    >
      <div
        className={`${currentRefinements.length > 0 ? 'mb-4 mt-4' : 'my-0'}`}
      >
        <Text
          {...{
            className: 'text-xl font-medium text-brand mb-4',
            content: `${resultsCount} Orders`
          }}
        />
        <CurrentRefinements
          {...{
            classNames: {
              ...currentRefinementClassGroup
            },
            excludedAttributes: ['query'],
            transformItems: transformCurrentRefinementItemsLabel
          }}
        />
      </div>
      <NoResultsBoundary
        fallback={
          <PageNotFound
            {...{
              className: 'py-20',
              description: `Sorry, we couldn't find any matches for "${query}"`,
              iconHeight: 314,
              iconWidth: 805,
              notFoundImage: 'search-result-not-found.svg',
              title: 'No search result'
            }}
          />
        }
      >
        <InfiniteHits
          {...{
            classNames: {
              item: 'p-0 border-none shadow-none',
              list: 'flex flex-col gap-4',
              loadMore: 'border-none hover:bg-none text-brand font-medium',
              root: 'flex flex-col flex-1'
            },
            hitComponent: ({ hit: listCard }) => <ListCard {...{ listCard }} />,
            showPrevious: false,
            transformItems: memoizedHandleTransformItems,
            translations: { showMoreButtonText: 'Show more' }
          }}
        />
      </NoResultsBoundary>
    </div>
  );
});
OrderListSearchResultsPanel.displayName = 'OrderListSearchResultsPanel';

export default OrderListSearchResultsPanel;
