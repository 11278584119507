import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';

import { Footer, Loader, PageNotFound } from 'components/atomic/atoms';
import { ToastContainer } from 'components/atomic/molecules';
import { HeaderOrganism } from 'components/atomic/organisms';
import LdJSONSchemaScriptTag from 'components/general/LdJSONSchemaScriptTag';
import { mediaStoreBaseURL, staticMediaStoreBaseURL } from 'config/common';
import { PLANNER_PORTAL_URL } from 'services/connections.service';

const direction = 'ltr';

const StandardLayout = ({
  children,
  headerContainerStyle,
  headerStyle,
  headParameters = {},
  isMenuOpen,
  loading,
  mainStyle = 'md:mt-16',
  pageDataFound,
  pageNotFound: { description, title } = {},
  setIsMenuOpen,
  setShowToast,
  showFooter = false,
  showHeader = true,
  showToast = { show: false, status: false, message: '' }
}) => {
  const router = useRouter();

  if (loading) {
    return <Loader />;
  }

  const {
    canonicalPath,
    ldJson,
    metaDescription,
    metaTitle,
    ogDescription,
    ogImage,
    ogTitle
  } = headParameters;

  const ogImageSrc = ogImage
    ? `${mediaStoreBaseURL}/${ogImage}`
    : `${staticMediaStoreBaseURL}/icons/32.png`;
  const canonicalPathURL = canonicalPath
    ? `${PLANNER_PORTAL_URL}/${canonicalPath}`
    : null;

  return (
    <>
      <NextSeo
        canonical={canonicalPathURL}
        description={metaDescription}
        title={metaTitle}
        openGraph={{
          description: ogDescription,
          images: [{ url: ogImageSrc }],
          title: ogTitle,
          url: `${PLANNER_PORTAL_URL}${router.asPath}`
        }}
      />
      {ldJson && <LdJSONSchemaScriptTag ldJson={ldJson} />}
      <ToastContainer {...{ showToast, setShowToast }} />
      {pageDataFound ? (
        <div
          className={`flex flex-col justify-between h-screen ${
            isMenuOpen ? 'overflow-hidden' : ''
          }`}
          id='standard-layout-wrapper'
        >
          <div dir={direction}>
            {showHeader && (
              <HeaderOrganism
                {...{
                  headerContainerStyle,
                  headerStyle,
                  isMenuOpen,
                  setIsMenuOpen
                }}
              />
            )}
            <main
              className={`${mainStyle} min-h-80 md:min-h-96`}
              id='main-content'
            >
              {children}
            </main>
            {showFooter && <Footer />}
          </div>
        </div>
      ) : (
        <PageNotFound
          {...{
            title,
            description
          }}
        />
      )}
    </>
  );
};

export default StandardLayout;
