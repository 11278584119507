import { useState } from 'react';
import { Configure, InfiniteHits, InstantSearch } from 'react-instantsearch';

import {
  ActionCenterUserEventCard,
  NoResultsBoundary,
  PageNotFound,
  TabMenu
} from '@/components/atomic/atoms';
import { Text } from '@/components/atomic/nuclei';
import {
  DEFAULT_HITS_PER_PAGE,
  DEFAULT_TAB_CATEGORY,
  SECTION_NAME,
  TAB_CATEGORY_CONFIG_LIST
} from '@/config/searchkit/actionCenter';
import { NAVIGATION_INDEX } from '@/config/searchkit/navigation';

const UserEventListByEventStageBody = () => {
  const [selectedTabMenu, setSelectedTabMenu] = useState(DEFAULT_TAB_CATEGORY);
  return (
    <div className='flex gap-4 w-full'>
      <TabMenu
        {...{
          attribute: 'stage',
          defaultTabCategory: DEFAULT_TAB_CATEGORY,
          containerMenuListStyle: '!left-0 border-r border-neutral pr-1',
          menuListStyle: 'flex flex-col gap-2',
          selectedTabMenu,
          setSelectedTabMenu,
          showTabMenuCount: true,
          tabCategoryConfigList: TAB_CATEGORY_CONFIG_LIST,
          tabLabelStyle: '',
          tabStyle:
            'navigate-cart hover:border hover:border-brand bg-white text-sm px-4 py-[10px] cursor-pointer font-medium flex rounded-lg'
        }}
      />
      <div className='flex flex-col flex-1 gap-4 w-full'>
        <NoResultsBoundary
          fallback={
            <PageNotFound
              {...{
                className: 'pt-4',
                iconHeight: 320,
                iconWidth: 328,
                notFoundImage: 'dashboard-event-not-found.svg',
                title: `Awesome! You're all caught up`,
                titleStyle: 'font-light text-dim-gray mt-2'
              }}
            />
          }
        >
          <InfiniteHits
            {...{
              classNames: {
                item: 'p-0 border-none shadow-none w-full !block',
                list: 'flex flex-1 flex-col gap-4 w-full',
                loadMore:
                  '!border-none !hover:bg-none !text-brand !font-medium searchkit-filter-show-more-button !bg-none !shadow-none  !pb-0 !h-3',
                root: 'flex flex-col flex-1 max-h-[30.5rem] overflow-auto no-scrollbar',
                showMore: 'searchkit-filter-show-more-button !pb-0 !h-3'
              },
              hitComponent: ({ hit }) => (
                <ActionCenterUserEventCard {...{ hit }} />
              ),
              showPrevious: false,
              translations: {
                showMoreButtonText: 'Show more'
              }
            }}
          />
        </NoResultsBoundary>
      </div>
    </div>
  );
};

const UserEventListByEventStage = ({ searchClient, selectedPlannerId }) => (
  <div className='my-4'>
    <Text
      {...{
        className: 'text-dim-gray font-semibold',
        content: 'See How Your Events Are Shaping Up'
      }}
    />
    <div className='flex gap-4 shadow-navigate-cart rounded-lg bg-white p-4 my-4'>
      <InstantSearch
        {...{
          future: {
            preserveSharedStateOnUnmount: true
          },
          indexName: NAVIGATION_INDEX,
          initialUiState: {
            [NAVIGATION_INDEX]: {
              menu: {
                stage: DEFAULT_TAB_CATEGORY
              }
            }
          },
          searchClient
        }}
      >
        <Configure
          {...{
            hitsPerPage: DEFAULT_HITS_PER_PAGE,
            plannerId: selectedPlannerId,
            sectionName: SECTION_NAME.GLANCE_EVENT_PIPELINE
          }}
        />
        <UserEventListByEventStageBody />
      </InstantSearch>
    </div>
  </div>
);

export default UserEventListByEventStage;
